<template>
	<div class="waypoint" v-if="content!==null">
		<div class="waypoint-distance">
			<span v-if="false">{{ distance }}</span>
			<span v-if="showDot"><img class="dot" src="/assets/icons/basic_dot.png"></span>
		</div>
		<div class="waypoint-card">
			<GalleryComponent v-if="sliderImages!=null" :slider-images="sliderImages"/>
			
			<div class="waypoint-card-content">
				<div class="card-body">
					<h3 class="title">{{ title }}</h3>
					
					<div class="text mb30">
						<div class="text-list" v-if="description" v-html="getContent()"></div>
					</div>
					
					<div class="audio-walk-section" v-if="audioPath!==null">
						<div class="d-flex">
							<i class="material-icons" aria-hidden="true">headphones</i>
							<div class="text">
								<p class="header">{{ audioTitle }}</p>
							</div>
						</div>
						<audio-player :file="audioPath"></audio-player>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import { smartSubStr, getMonth, getWeekday, formatTime, getFieldValues, getNextAppointment, isOneOfTheAppointmentsNow, getPlaceholderImage, json_decode, isset } from '@/utils/helpers';

export default {
	name: "WayPoint",
	components: {
		Splide,
		GalleryComponent: () => import('@/components/details/gallery.vue'),
		AudioPlayer: () =>import('@/components/controls/AudioPlayer.vue'),
	},
	props: {
		content:{
			required: true,
			default: () => ({})
		},
		distance:{
			type:String,
			default: '',
			required: false
		},
		showDot:{
			type: Boolean,
			default: true
		}
	},
	data() {
		return {
			maxLength: 140,
			showMore: false,
			image: null,
			sliderOptions: {
				type: 'loop',
				perPage: 3,
				perMove: 1,
			},
			lightBoxIndex: 0,
			lightBoxVisible: false,
		}
	},
	watch: {
		content: {
			handler: function(images) {
				if(this.content.content_type_name !== 'geschichte'){
					var pictures = getFieldValues(this.content,'teaser-bild');
				}
				else{
					if(this.content.images !==null){
						var pictures = this.content.images;
					}
				}
				if(pictures != null){
					if(Array.isArray(pictures)){
						this.image = pictures;
					}
					else{
						this.image = [pictures];
					}
				}
			},
			deep: true,
			immediate: true
		},
	},
	computed:{
		sliderImages(){
			var teaserImgs = [];
			var images = getFieldValues(this.content, 'bilder');
			if(images !== null && Array.isArray(images)){
				for(var i = 0; i < images.length; i++){
					var image = json_decode(images[i]);
					if(typeof image === "object"){
						teaserImgs.push({image:this.$backendUrl+image.path,class:'teaser-img', copyright:image.copyright,description:image.description});
					}
					else {
						teaserImgs.push({image:this.$backendUrl+image,class:'teaser-img', copyright:null,description:null});  
					}
				}
			}
			else if(typeof images === 'string' || images instanceof String){
				var img = json_decode(images);
				teaserImgs.push({image:this.$backendUrl+img.path,class:'teaser-img', copyright:img.copyright,description:img.description});
			}
			return teaserImgs;
		},
		imgs(){
			if(this.sliderImages.length > 0){
				var lightBoxImages = [];
				for(var i= 0; i<this.sliderImages.length; i++){
					lightBoxImages.push({src: this.sliderImages[i].image,alt:this.sliderImages[i].description})
				}
				return lightBoxImages;
			}
			return null;
		},
		linkToTitle(){
			return "Mehr Informationen zu: "+this.content.title;
		},
		btnShowMore() {
			if (this.description.length > this.maxLength) {
				return true;
			}
			return false;
		},
		title(){
			if(this.content!=null){
				return this.content.title;
			}
			return "";
		},    
		description(){
			var description = getFieldValues(this.content,'body');
			if(description!==null){
				return description;
			} 
			console.log("Klappt nicht");
			return "";
		},
		logo(){
			var logo = getFieldValues(this.content,'logo');
			if(logo != null){
				return this.$backendUrl+logo;
			}
			return null;
		},
		audioWalk(){
			if(this.content!==null){
				return getFieldValues(this.content,'audiowalk');
			}
			return null;
		},
		audioTitle(){
			if(this.audioWalk!==null){
				return  'Audio: ' + getFieldValues(this.audioWalk,'title');
			}
			return 'Mit Audio'
		},
		audioPath(){
			if(this.audioWalk!==null){
				var audiofile = getFieldValues(this.audioWalk,'audio-datei');
				if(isset(audiofile)){
					audiofile = json_decode(audiofile);
					return this.$backendUrl + audiofile.path;
				}
			}
			return null
		},  
		
		backgroundImage(){
			return 'background-image: url("' + this.teaserImage + '")';
		},
		teaserImage(){
			var images = this.image;
			if(images!=null){
				var image = images[Math.floor(Math.random()*images.length)];
				image = json_decode(image);
				if(typeof image === "object"){
					return this.$backendUrl+image.path;
				}
				else{
					return this.$backendUrl+image;
				}
			}
			
			/*we'll need an alternative if there is no image*/
			return this.getPlaceholderImage(this.category);
		},
	},
	methods:{
		getMonth,
		getWeekday, 
		formatTime, 
		getFieldValues, 
		getNextAppointment, 
		isOneOfTheAppointmentsNow, 
		getPlaceholderImage,
		smartSubStr,
		json_decode,
		isset,
		getBackgroundImage(image){
			return 'background-image: url("' + image + '")';
		},
		showLightbox(index){
			this.lightBoxVisible = true;
			this.lightBoxIndex = index;
		},
		hideLightbox(){
			this.lightBoxVisible = false;
		},
		getContent(){
			return this.description
		},
		limitedString() {
			if (this.description.length > this.maxLength) {
				return smartSubStr(this.description, this.maxLength) + ' ...';
			}
			
			return this.description;
		},
	}
}
</script>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.slide {
	padding: 10px;
}

.waypoint {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	margin-bottom: 30px;
	margin-top: 60px;
	width: 100%;
	
	.audio-walk-section{
		
		.text{
			margin-left: 10px;
			display: inline-block;
			vertical-align: middle;
		}
		
		.header{
			font-size: 14px;
			font-family: 'elliot-bold', sans-serif;
			font-weight: 700;
		}
		
		i.material-icons{
			font-size: 25px;
			vertical-align: middle;
		}
	}
	
	.btn-showmore {
		display: flex;
		justify-content: center;
		vertical-align: middle;
		align-items: center;
		text-transform: capitalize;
		color: $darkGray;
		margin-top: 5px;
		margin-bottom: 15px;
		
		i.material-icons{
			position: relative;
		}
		
		&:hover,
		&:active,
		&:focus {
			text-decoration: none;
		}
	}
	
	
	&-distance {
		position: relative;
		width: 60px;
		min-width: 60px;
		margin-right: 30px;
		
		@media(max-width:991px){
			display: none;
		}
		
		span {
			position: relative;
			top: 30px;
			background: #fff;
			display: inline-block;
			text-align: center;
			width: 62px;
			z-index: 2;
			
			@media (max-width: 500px) {
				width: 40px;
			}
		}
		
		.dot {
			width: 10px;
			height: 10px;
		}
		
		&:after {
			content: '';
			position: absolute;
			top: -60px;
			left: 30px;
			width: 3px;
			background: $secondary;
			height: calc(100% + 60px);
			z-index: 1;
			
			@media (max-width: 500px) {
				left: 15px;
			}
		}
		
		@media (max-width: 500px) {
			width: 40px;
			max-width: 40px;
			margin-right: 10px;
		}
	}
	
	&-card {
		
		position: relative;
		display: block;
		max-width: calc(100% - 90px);
		width: 100%;
		padding-left: 30px;
		
		@media(max-width:991px){
			padding-left: 0;
			max-width: 100%;
		}
		
		.card-img {
			max-width: 250px;
			border-bottom-left-radius: 50px;
			position: relative;
			min-height: 140px;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center center;
			
			@media(max-width: 991px){
				width: 100%;
				max-width: 100%;
				border-bottom-left-radius: 0;
			}
			
			img {
				width: 100%;
			}
		}
		
		&-content{
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			
			@media(max-width: 991px){
				display: block;
			}
		}
		
		.card-body {
			padding: 25px;
			
			@media(max-width:991px){
				padding: 0 !important;
				margin-top: 20px;
			}
			.title {
				font-size: 14px;
				margin-bottom: 5px;
			}
			
			.location {
				margin-bottom: 10px;
				display: inline-flex;
				vertical-align: middle;
				align-items: center;
				
				i {
					color: rgba($color: #686B6F, $alpha: 0.5);
					margin-right: 10px;
				}
				
				span {
					display: inline-block;
					color: rgba($color: #1F2041, $alpha: 0.5);
				}
			}
			
			.appointments{
				@media (max-width: 600px){
					display:none;
				}
			}
			
			.more-info {
				@media(max-width:991px){
					margin-bottom: 15px;
				}
			}
			
			.text-list {
				color: rgba($color: #1F2041, $alpha: 0.8);
				font-size: 14px;
				line-height: 24px;
				
				@media (max-width: 500px) {
					font-size: 13px;
					line-height: 22px;
				}
			}
			
			@media (max-width: 500px) {
				padding: 15px;
			}
		}
		
		.card-detail__features {
			display: flex;
			
			@media (max-width: 600px) {
				display: block;
				text-align: center;
			}
			
			.adresse {
				padding-top: 13px;
				align-items: normal;
			}
			
			li {
				flex: 1;
				display: inline-flex;
				vertical-align: middle;
				align-items: center;
				
				@media(max-width:991px){
					display: flex;
				}
				
				i, img {
					margin-right: 10px;
				}
				
				img {
					display: inline-block;
					max-height: 24px;
				}
				
				&.highlight {
					color: $success !important;
					
					span {
						font-weight: 600;
					}
				}
			}
		}
	}
}

.profile-image {
	position: relative;
	z-index: 1;
	
	
	&-placeholder {
		height: 116px;
		background-repeat: no-repeat;  
		background-position: center center; 
		background-size: cover;
		&.logo{
			background-size: contain!important;
		}
	}
}

.splide-carousel{
	padding: 0 25px;
	
	@media(max-width:991px){
		padding: 0;
	}
}
</style>
